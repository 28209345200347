@tailwind base;
@tailwind components;
@tailwind utilities;

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/dmmono/v10/aFTR7PB1QTsUX8KYvrGyIYc.woff")
    format("woff");
}

@font-face {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/dmmono/v10/aFTR7PB1QTsUX8KYvumzIYc.woff")
    format("woff");
}

* {
  font-family: "DM Mono";
}


html,
body {
  height: 100%;
  background: #111111
}


/* #root {
  background-image: linear-gradient(
      0.97deg,
      #000000 -0.42%,
      rgba(0, 0, 0, 0) 72.89%
    ),
    url("../images/containerbg.webp");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
} */

.buttonWrapper {
  padding-right: 16px;
}

@media (max-width: 1024px) {
  #root {
    background-image: url("../images/t_containerbg.webp");
  }
}

@media (max-width: 400px) {
  #root {
    background-image: url("../images/m_containerbg.webp");
  }
}


.fade-enter {
  opacity: 0;
  transition: 250ms ease-in;
}
.fade-exit {
  opacity: 0;
  transition: 250ms ease-in;

}
.fade-exit-active {
  opacity: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}