.container {
  display: flex;
  width: 100%;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  padding: 30px 8%;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 156px;

    &:first-of-type {
      margin-right: 1rem;
    }
  }

  .totalSupply {
    flex-direction: column;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);

    @media (max-width: 1024px) {
      display: none;
    }

    p {
      font-size: 18px;
      line-height: 120%;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    text-align: right;
    color: #ffffff;
    @media (max-width: 410px) {
      text-align: center;
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;
    padding: 0.5rem;
  }

  .copyBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-shadow: 1px 1px 10px #ffffff;
    text-transform: uppercase;

    @media (max-width: 768px) {
      width: 100%;
      max-width: none;
    }

    @media (max-width: 555px) {
      align-items: center;
      height: 80px;
      padding: 22px 0;
      width: 100%;
      max-width: none;
    }

    p {
      @media (max-width: 740px) {
        font-size: 0.9rem;
      }
      @media (max-width: 555px) {
        text-align: center;
        text-align: center;
      }
    }
  }
}
