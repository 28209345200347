.resize {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  z-index: 9000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.resizeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  max-width: 370px;
  text-align: center;

  h5,
  h6 {
    font-family: DM Mono;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 18px;
  }
}
.main {
  width: 100%;
  z-index: 40;
  min-height: 78vh;
  z-index: 1;

  .bossBear {
    margin: auto;

    @media (max-width: 500px) {
      margin: 0;
    }
    @media (max-height: 800px) {
      margin: 0;
    }
  }

  & .imgContainer {
    max-width: 540px;
    display: flex;
    justify-content: center;
    margin: 50px auto;
    min-width: 324px;
    @media (max-height: 845px) {
      max-width: 450px;
    }
    @media (max-width: 1024px) {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      opacity: 0.2;
    }
  }

  .whitelistImgContainer {
    display: none;
  }

  // .bossBear {
  //   @media (max);
  // }
}

.headerContainer {
  z-index: 25;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 38px 0;

  @media (max-width: 937px) {
    padding: 36px 41px;
  }

  @media (max-width: 500px) {
    padding: 31px 24px;
  }

  @media (max-height: 850px) {
    padding: 19px 10px;
  }
}

.headerImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 937px) {
    justify-content: flex-start;
  }
}

.logo {
  width: 179px;
  height: 117px;
  @media (max-width: 937px) {
    width: 122px;
    height: 80px;
  }
}

.headerImg {
  @media (max-width: 580px) {
    width: 114px;
  }
}

.headerText {
  font-family: DM Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 67.2px;
  margin-top: 5vh;
  text-shadow: 1px 1px 10px #ffffff;
  /* identical to box height, or 98% */

  text-align: center;
  letter-spacing: 0.135em;
  text-transform: uppercase;

  color: #ffffff;

  @media (max-width: 580px), (max-height: 850px) {
    font-size: 55px;
    line-height: 45px;
  }
  @media (max-width: 780px) {
    font-size: 44px;
    line-height: 52px;
    margin-top: 78px;
  }
  @media (max-width: 580px) {
    font-size: 28px;
    line-height: 33.6 px;
    margin-top: 72px;
  }
}

.headerWalletContainer {
  position: absolute;
  right: 80px;

  @media(max-width: 500px){
    right: 20px;
  }
}


.main {
  width: 100%;
  z-index: 40;

  .bossBear {
    margin: auto;

    @media (max-width: 500px) {
      margin: 0;
    }
    @media (max-height: 800px) {
      margin: 0;
    }
  }

  .imgContainer {
    max-width: 540px;
    display: flex;
    justify-content: center;
    margin: 50px auto;
    min-width: 324px;
    @media (max-height: 845px) {
      max-width: 450px;
    }
    @media (max-width: 1024px) {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      opacity: 0.2;
    }
  }

  .whitelistImgContainer {
    display: none;
  }

  // .bossBear {
  //   @media (max);
  // }
}

.headerContainer {
  z-index: 25;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 38px 0;

  @media (max-width: 937px) {
    padding: 36px 41px;
  }

  @media (max-width: 500px) {
    padding: 31px 24px;
  }

  @media (max-height: 850px) {
    padding: 19px 10px;
  }
}

.headerImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 937px) {
    justify-content: flex-start;
  }
}



.logo {
  width: 179px;
  height: 117px;
  @media (max-width: 937px) {
    width: 122px;
    height: 80px;
  }
}

.headerImg {
  @media (max-width: 580px) {
    width: 114px;
  }
}

.headerText {
  font-family: DM Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 67.2px;
  margin-top: 5vh;
  text-shadow: 1px 1px 10px #ffffff;
  /* identical to box height, or 98% */

  text-align: center;
  letter-spacing: 0.135em;
  text-transform: uppercase;

  color: #ffffff;

  @media (max-width: 580px), (max-height: 850px) {
    font-size: 55px;
    line-height: 45px;
  }
  @media (max-width: 780px) {
    font-size: 44px;
    line-height: 52px;
    margin-top: 78px;
  }
  @media (max-width: 580px) {
    font-size: 28px;
    line-height: 33.6 px;
    margin-top: 72px;
  }
}

.headerWalletContainer {
  position: absolute;
  right: 80px;

  @media(max-width: 500px){
    right: 20px;
  }
}

.walletContainer {
  height: 100%;
  z-index: 4;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.countdownContainer {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
