

.button {
  background: #fff;
  border: 1px solid #fff;
  font-size: .75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  padding: 12px 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  transition: all .2s ease-in;
  border-radius: 0;
  border-radius: 0;
  box-shadow: 0 0 16px 0 #fff;
  color: #000;
}

.buttonOpen {
  background: transparent;
  border: 1px solid #fff;
  box-shadow: none;
  color: #fff;
}